/**
 * This file is part of Analytikal.
 *
 * (c) 1 Giant Leap Holding BV
 *
 * For the full copyright and license information, please view the LICENSE file that was distributed with this source code.
 */

import { useRuntimeConfig } from '#app'
import { init, replayIntegration } from '@sentry/nuxt'

const config = useRuntimeConfig()
if (config.public.SENTRY.ENABLED) {
  init({
    enabled: config.public.SENTRY.ENABLED,
    autoSessionTracking: true,
    debug: config.public.SENTRY.ENABLED && import.meta.env.DEV,
    dsn: config.public.SENTRY.DSN,
    environment: config.public.SENTRY.ENVIRONMENT,
    integrations: [
      replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    maxValueLength: 4069,
    release: config.public.SENTRY.RELEASE,
    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
  })
}
